import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { useTheme } from '@mui/material/styles';
// import LogoImage from './LogoImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundImage: 'url("./bg.jpg")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      // width: theme.dimensions.sidebarWidthTablet,
      width: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    background: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    // [theme.breakpoints.up('lg')]: {
    //   padding: theme.spacing(0, 25, 0, 0),
    // },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
    background: 'white',
    opacity: '95%',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      width: '95%',
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  // const theme += useTheme();

  return (
    <main className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form}>
          {children}
        </form>
      </Paper>
    </main>
  );
};

export default LoginLayout;
