import {
  grey, common,
} from '@mui/material/colors';

const colors = {
  white: common.white,
  background: grey[50],
  primary: '#107bff',
  secondary: '#4caf50',
  positive: '#4caf50',
  medium: '#ffa000',
  negative: '#f44336',
  neutral: grey[500],
  geometry: '#3bb2d0',
};

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  colors,
};
